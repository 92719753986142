import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { CacheService } from '../services/chache.service';
import { HelperService } from '../services/helper.service';


@Injectable()
export class Logged {

  constructor(private cache: CacheService, private router: Router) {}

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.cache.getToken();
    // Si no hay token o ha expirado redirijo a login con los parámetros que tenga en la url
    if (!token || HelperService.isTokenExpired(token)) {
      await this.router.navigate(['/login'], { queryParams: { return: state.url } });
      return false;
    }
    return true;
  }
}
