<section class="relative">
  <div mat-dialog-close class="dialog-container__close dialog-container__close--l dialog-container__close--white cursor-pointer absolute" aria-label="Close dialog">
    <mat-icon svgIcon="close-circle" class="mr-2"></mat-icon>
  </div>
  <div class="flex flex-col">
    <div class="ml-4 my-4 pt-2">
      <span class="overline-o1">{{sent ? ('passwordRecovery.emailSent' | translate) : ('passwordRecovery.title' | translate)}}</span>
    </div>
    <div><hr class="divider"></div>
    <div class="my-4">
      <form (ngSubmit)="sendMail()">
        <div class="mx-4">
          <app-input *ngIf="!sent" [type]="'text'" [control]="form.get('email')" [label]="'login.email' | translate" class="mb-1"></app-input>
          <div *ngIf="sent" class="py-4 mb-2">
            <span>{{'passwordRecovery.lookSpam' | translate}}</span>
            <br><br>
          </div>
        </div>
        <hr class="divider mr-4">
        <div class="flex justify-end pt-2 mx-4">
          <app-button-secondary *ngIf="!sent" class="mt-2" [type]="'submit'">{{'buttons.continue' | translate}}</app-button-secondary>
          <app-button-secondary *ngIf="sent" class="mt-2" (click)="close()">{{'buttons.back' | translate}}</app-button-secondary>
        </div>
      </form>
    </div>
  </div>
</section>
