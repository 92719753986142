<div *ngIf="organization" class="flex flex-col mb-4 px-4">
  <div class="bg-img">
    <h3 class="overline-o2 whitespace-nowrap grey-700">{{'organizationData.title' | translate}}</h3>
    <h2 class="title-t2 mt-4">{{organization.name}}</h2>
    <p class="body-b2">{{organization.legalId}}</p>
    <div class="my-4">
      <mat-icon svgIcon="lock" class="mr-2"></mat-icon>
      <span class="body-b2 font-bold">{{(organization.public ? 'organizationData.public' : 'organizationData.private') | translate}} </span>
    </div>
    <div class="body-b2 my-2">
      <p>{{organization.alias}}</p>
      <p>{{organization.email}}</p>
      <div>
        <p class="inline-block mt-0 mb-2">
          <span *ngIf="organization.address?.street" class="comma">{{organization.address?.street}}</span>
          <span *ngIf="organization.address?.locality" class="comma"> {{organization.address?.locality}}</span>
        </p>
        <p class="inline-block mt-0 mb-2">
            <span *ngIf="organization.address?.subdivision || organization.address?.postalCode || organization.address?.country" class="comma trim">
              {{organization.address?.subdivision}}
              {{organization.address?.postalCode}}
              {{organization.address?.country}}
            </span>
          <span *ngIf="organization.address?.astronomicalBody" class="comma capitalize"> {{('select.astronomicalBody.' + organization.address?.astronomicalBody) | translate}}</span>
        </p>
      </div>
    </div>

    <hr class="divider w-1/2 -ml-6 my-2">

    <h4 class="overline-o3 my-2">{{'organizationData.contact' | translate}}</h4>
    <div class="body-b2">
      <p>{{organization.contactName}}</p>
      <p>{{organization.contactPhone}}</p>
      <p>{{organization.contactEmail}}</p>
    </div>
    <div class="my-5">
      <mat-checkbox class="-ml-2">
        <mat-label class="link">
          {{'organizationData.services' | translate}}
        </mat-label>
      </mat-checkbox>
    </div>

  </div>

  <hr class="divider block">
  <h3 class="overline-o2 my-2">{{'organizationData.users' | translate}}</h3>
  <h2 class="title-t1 my-2">{{'organizationData.members' | translate}}</h2>

  <div class="my-4">
    <div class="shadow-dark">
      <table mat-table [dataSource]="dataSource" class="w-full">
        <!-- name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{'organizationMembers.name' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex flex-col">
              <div><span> {{element.name}}</span></div>
            </div>
          </td>
        </ng-container>

        <!-- email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>{{'organizationMembers.email' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <span class="ml-2">{{element.email}}</span>
            </div>
          </td>
        </ng-container>

        <!-- invited Date Column -->
        <ng-container matColumnDef="invitedAt">
          <th mat-header-cell *matHeaderCellDef>{{'organizationMembers.invitedAt' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.invitedAt | date: 'shortDate'}} </td>
        </ng-container>

        <!-- registered date Column -->
        <ng-container matColumnDef="registeredAt">
          <th mat-header-cell *matHeaderCellDef>{{'organizationMembers.registeredAt' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.registeredAt | date: 'shortDate' }} </td>
        </ng-container>

        <!-- enabled Column -->
        <ng-container matColumnDef="enabled">
          <th mat-header-cell *matHeaderCellDef class="center">{{'organizationMembers.enabled' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex justify-center w-full">
              <mat-checkbox [checked]="element.status === 'active'" (change)="patchMember($event, element.user?.id)"></mat-checkbox>
            </div>
          </td>
        </ng-container>

        <!-- admin Column -->
        <ng-container matColumnDef="admin">
          <th mat-header-cell *matHeaderCellDef class="center">{{'organizationMembers.admin' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex justify-center w-full">
              <mat-checkbox [checked]="element.isAdmin" disabled></mat-checkbox>
            </div>
          </td>
        </ng-container>

        <!-- manager Column -->
        <ng-container matColumnDef="manager">
          <th mat-header-cell *matHeaderCellDef class="center">{{'organizationMembers.manager' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex justify-center w-full">
              <mat-checkbox [checked]="element.isManager" disabled></mat-checkbox>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>

      </table>
    </div>
    <div class="mt-6">
      <mat-checkbox [checked]="organization.settings.addCreatorAsManager" disabled></mat-checkbox>
      <span class="ml-2">{{'organizationData.managerAsCreator' | translate}}</span>
    </div>
  </div>

</div>
