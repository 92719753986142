import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { noop } from 'rxjs';

import packageJSON from '../../../../package.json';
import { ApiService } from '../../../common/services/api.service';
import { MainEventService } from '../../../common/shared/main-event.service';
import { CacheService } from './core/services/chache.service';
import { HelperService } from './core/services/helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  isLogged = !!this.cache.getToken();
  timers = { warnSession: 0, closeSession: 0 };

  constructor(private cache: CacheService, private router: Router, private api: ApiService, private dialog: MatDialog) {}

  ngOnInit() {
    MainEventService.login.subscribe(res => {
      // centralizo la llamada a cache service en el app.ts
      if (res) {
        this.isLogged = true;
        this.cache.setToken(res.token, !res.user.organization);
        this.cache.locale = res.user.locale;
        this.addSessionLimitTime(res.token);
      } else {
        this.clearTimers();
        this.dialog.closeAll();
        this.cache.clearStorage();
        this.isLogged = false;
      }
    });

    if (this.cache.getToken()) this.cache.getUserPromise().then(user => MainEventService.login.emit({ user, organization: undefined, token: this.cache.getToken() }));

    MainEventService.socketConnected.subscribe(() => this.checkLastVersion());
    setInterval(() => this.checkLastVersion(), 86400000); // 24h in seconds
  }

  private addSessionLimitTime(token: string) {
    const time = HelperService.getTimeToExpireToken(token);
    this.clearTimers();

    this.timers.warnSession = window.setTimeout(() => { // Me guardo el id para quitarlo si se hace logout
      this.api.logWarn('toast.warnSession');
    }, time - 60000);

    this.timers.closeSession = window.setTimeout(() => { // Me guardo el id para quitarlo si se hace logout
      this.api.logWarn('toast.closeSession');
      MainEventService.login.next();
      this.router.navigate(['/login']).then(noop);
    }, time);
  }

  private clearTimers() {
    clearTimeout(this.timers.warnSession);
    clearTimeout(this.timers.closeSession);
    this.timers = { warnSession: 0, closeSession: 0 };
  }

  private checkLastVersion() {
    this.api.getLastVersion().subscribe(serverVersion => {
      if (serverVersion && serverVersion !== packageJSON.version) {
        this.api.logWarn('toast.newVersion', true);
        setTimeout(() => window.location.reload(), 5000);
      }
    });
  }
}
