import { Component, OnInit } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { firstValueFrom } from 'rxjs';

import packageJSON from '../../../../../../package.json';
import { ApiService } from '../../../../../common/services/api.service';
import { CustomValidators } from '../../../../../common/shared/custom-validators';
import { MainEventService } from '../../../../../common/shared/main-event.service';
import { CacheService } from '../../core/services/chache.service';
import { EnvService } from '../../core/services/env.service';
import { RecoveryPasswordModalComponent } from './recovery-password-modal/recovery-password-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-login-bo',
  templateUrl: './login-bo.component.html',
  styleUrls: ['./login-bo.component.scss'],
})
export class LoginBOComponent implements OnInit {
  version = packageJSON.version;
  passwordRecovery = false;
  form = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(6), CustomValidators.strongPassword]],
    email: ['', [Validators.required, CustomValidators.validateEmail]],
    type: ['password'],
  });

  passChangeForm = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(6), CustomValidators.strongPassword]],
    repeatPassword: ['', [Validators.required, Validators.minLength(6), CustomValidators.strongPassword]],
  }, { validators: [CustomValidators.matchPassword] });

  queryParams!: Params;

  constructor(private fb: NonNullableFormBuilder,
              private api: ApiService,
              private ar: ActivatedRoute,
              public dialog: MatDialog,
              private reCaptchaV3Service: ReCaptchaV3Service,
              private env: EnvService,
              private router: Router,
              private cache: CacheService) { }

  ngOnInit(): void {
    MainEventService.login.emit();
    this.ar.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams) => {
      if (queryParams.code) this.passwordRecovery = true;
      this.queryParams = queryParams;
    });
  }

  clearForm() {
    this.form.reset();
  }

  async sendLogin() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return this.api.logWarn('login.incompleteForm');
    }
    const data = this.form.getRawValue();
    if (data.email) data.email = data.email.trim();
    const captchaResponse = this.env.captchaKey ? await this.reCaptchaV3Service.executeAsPromise(this.env.captchaKey, 'login', { useGlobalDomain: true }) : '';
    this.api.loginBO(data, captchaResponse).subscribe(res => {
      this.cache.setUser(res.user);
      MainEventService.login.emit(res);
      this.router.navigateByUrl('/');
    });
  }

  openRecoveryPassword() {
    this.dialog.open(RecoveryPasswordModalComponent, { width: '344px', minHeight: 252, disableClose: true });
  }

  async sendPassChange() {
    if (this.passChangeForm.invalid) {
      if (this.passChangeForm.errors?.confirmPassword) return this.api.logError('passwordRecovery.passwordError');
      this.passChangeForm.markAllAsTouched();
      return this.api.logWarn('passwordRecovery.formError');
    }
    const form = this.passChangeForm.getRawValue();
    if (this.queryParams.code) {
      await this.setPasswordAfterRecovery(this.queryParams, form.password);
    }
  }

  private async setPasswordAfterRecovery(queryParams: Params, password: string) {
    const data = {
      code: queryParams.code,
      email: queryParams.login,
      password: password,
      locale: queryParams.locale || this.cache.locale,
    };
    await firstValueFrom(this.api.confirmPasswordChangeBO(data));
    this.api.log('newPassword.passwordChanged');
    this.passwordRecovery = false;
    await this.router.navigate(['login']);
  }
}
