import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { MainEventService } from '../../../../../common/shared/main-event.service';

@Component({
  selector: 'app-home-bo',
  templateUrl: './home-bo.component.html',
  styleUrls: ['./home-bo.component.scss'],
})
export class HomeBOComponent {

  constructor(private router: Router) { }

  exit(link: string) {
    MainEventService.login.next();
    this.router.navigate([link]);
  }

}
