<div class="flex w-full justify-center login-body h-full relative">
  <app-menu-locale [cornerPosition]="true"></app-menu-locale>
  <div class="pt-5">
    <div class="flex flex-col justify-center my-2">
      <img src="./assets/img/logo-usyncro_backoffice-black-300.png" alt="logo">
      <div class="flex justify-center my-2">
        <span class="caption-c2 version">V{{version}}</span>
      </div>
    </div>
    <div class="flex justify-center">
      <img *ngIf="!passwordRecovery" src="./assets/img/organization.png" alt="organization">
      <img *ngIf="passwordRecovery" src="./assets/img/key.png" alt="key">
    </div>
    <div *ngIf="passwordRecovery" class="shadow-modal mt-4 pt-4 pb-2">
      <form [formGroup]="passChangeForm" (ngSubmit)="sendPassChange()">
        <div class="justify-center px-5 mb-3">
          <span class="overline-o1">{{'newPassword.newPassword' | translate}}</span>
        </div>
        <hr class="divider">
        <div class="px-5 pt-3">
          <app-input [type]="'password'" [control]="passChangeForm.get('password')" [label]="'newPassword.title' | translate" class="mb-2"></app-input>
          <app-input [type]="'password'" [control]="passChangeForm.get('repeatPassword')" [label]="'newPassword.repeatPassword' | translate"></app-input>
        </div>
        <hr class="divider">
        <div class="py-3 w-full flex justify-end">
          <app-button-secondary class="mr-5" [type]="'submit'">{{'buttons.changePass' | translate}}</app-button-secondary>
        </div>
      </form>
    </div>
    <div *ngIf="!passwordRecovery" class="shadow-modal mt-4 pt-4 pb-2">
      <form [formGroup]="form" (ngSubmit)="sendLogin()">
        <div class="justify-center px-5 mb-3">
          <span class="overline-o1">{{'login.title' | translate}}</span>
        </div>
        <hr class="divider">
        <div class="px-5 pt-3">
          <app-input [type]="'text'" [control]="form.get('email')" [label]="'login.email' | translate" class="mb-2" data-test="loginEmail"></app-input>
          <app-input [type]="'password'" [control]="form.get('password')" [label]="'login.pass' | translate" data-test="loginPassword"></app-input>
          <app-button-link (click)="openRecoveryPassword()" text="{{'login.forgot' | translate}}" [style]="'primary'"></app-button-link>
        </div>
        <hr class="divider">
        <div class="py-3 w-full flex justify-evenly items-center">
          <app-button-link (click)="clearForm()" text="{{'login.cleanInfo' | translate}}" [style]="'primary'" iconLeft="redo" data-test="cleanInfoLogin"></app-button-link>
          <app-button-secondary [type]="'submit'" data-test="sendLogin">{{'buttons.enter' | translate}}</app-button-secondary>
        </div>
      </form>
    </div>
  </div>
</div>

