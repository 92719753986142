<div>
  <div *ngIf="organization" class="flex flex-col my-4 mx-6">
    <span class="overline-o2 font-grey">{{'subscription.client' |  translate}}</span>
    <span class="title-t1 mt-4">{{organization.name}}</span>
    <span class="body-b2">{{organization.legalId}}</span>
    <div class="w-40 mt-2">
      <app-select [type]="'plans'" [control]="plansForm" [label]="'subscription.plan' | translate"></app-select>
    </div>
    <div class="flex items-center">
      <div class="mr-2">{{'subscription.currencyType' | translate}}</div>
      <div class="w-32">
        <app-autocomplete-select [control]="form.get('monthlyPrice.currency')" [type]="'currency'"></app-autocomplete-select>
      </div>
    </div>
  </div>
  <div class="ml-6 overline-o2 font-grey mb-2">{{'subscription.subscription' | translate}}</div>
  <div class="mx-6 flex space-x-6">
    <app-subscription-card [type]="'records'" [control]="form.get('products.records')"></app-subscription-card>
    <app-subscription-card [type]="'members'" [control]="form.get('products.members')"></app-subscription-card>
    <app-subscription-card [type]="'storage'" [control]="form.get('products.storage')"></app-subscription-card>
  </div>
  <div class="ml-6 overline-o2 font-grey my-3">{{'subscription.total' | translate}}</div>
  <div class="mx-6 mb-4">
    <app-subscription-card [type]="'total'" [control]="form.controls.monthlyPrice"></app-subscription-card>
  </div>
  <app-button-secondary (click)="updateSubscription()" class="ml-6">{{'subscription.savePlan' | translate}}</app-button-secondary>
</div>
