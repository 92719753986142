import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { HttpCustomLoader, MyMissingTranslationHandler } from '../../../common/i18n/common-i18n';
import { MyCommonModule } from '../../../common/my-common.module';
import { LoadIconsService } from '../../../common/services/load-icons.service';
import { MainCacheService } from '../../../common/shared/main-chache.service';
import { MainEnvService } from '../../../common/shared/main-env.service';
import { MainEventService } from '../../../common/shared/main-event.service';
import { MainHelperService } from '../../../common/shared/main-helper.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FooterComponent } from './components/footer/footer.component';
import { Logged } from './core/guard/auth.guard';
import { CacheService } from './core/services/chache.service';
import { EnvService } from './core/services/env.service';
import { EventService } from './core/services/event.service';
import { HelperService } from './core/services/helper.service';
import { HomeBOComponent } from './routes/home-bo/home-bo.component';
import { LoginBOComponent } from './routes/login-bo/login-bo.component';
import { RecoveryPasswordModalComponent } from './routes/login-bo/recovery-password-modal/recovery-password-modal.component';
import { BillingComponent } from './routes/organization-detail/billing/billing.component';
import { OrganizationDataComponent } from './routes/organization-detail/organization-data/organization-data.component';
import { OrganizationDetailComponent } from './routes/organization-detail/organization-detail.component';
import { SubscriptionComponent } from './routes/organization-detail/subscription/subscription.component';
import { SubscriptionCardComponent } from './routes/organization-detail/subscription/suscription-card/subscription-card.component';
import { OrganizationListComponent } from './routes/organization-list/organization-list.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginBOComponent,
    HomeBOComponent,
    RecoveryPasswordModalComponent,
    OrganizationListComponent,
    OrganizationDetailComponent,
    SubscriptionComponent,
    BillingComponent,
    OrganizationDataComponent,
    FooterComponent,
    SubscriptionCardComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MyCommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: HttpCustomLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
    }),
  ],
  providers: [
    Logged,
    EnvService,
    {
      provide: APP_INITIALIZER,
      useFactory: (env: EnvService, loadIcons: LoadIconsService, cache: CacheService) => async () => {
        await env.setEnv();
        await loadIcons.loadIcons();
        await cache.init();
      },
      multi: true,
      deps: [EnvService, LoadIconsService, CacheService],
    },
    // Con esto le inyectamos al CommonModule una instancia de los servicios del BackOffice. Es necesario usar el factory para que se mantenga la misma instancia incializada
    { provide: MainEnvService, useFactory: (env: EnvService) => env, deps: [EnvService] },
    { provide: MainEventService, useFactory: (env: EventService) => env, deps: [EventService] },
    { provide: MainCacheService, useFactory: (env: CacheService) => env, deps: [CacheService] },
    { provide: MainHelperService, useFactory: (env: HelperService) => env, deps: [HelperService] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
