import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { ASTRONOMICAL_BODY, CUSTOMS_CLEARANCE_CODES, DISTANCE, DOCUMENTS, EVENT_CLASS, EVENT_SYSTEM, FUEL_TYPES_AIR, FUEL_TYPES_INTERPLANETARY, FUEL_TYPES_MARITIM, FUEL_TYPES_RAILWAY, FUEL_TYPES_RIVER, FUEL_TYPES_ROAD, LENGTHS, LOCALES, NOTIFICATION_TIMES, PACKAGES, PAYMENT_METHODS, PERMACTION, PLANS, RELEASE_TYPES, RESOURCE_TYPE, TEMPERATURES, TERMINAL_COMPANY, TERMINAL_TYPE, TRAILER_TYPES, TRANSPORT_TYPES, VOLUMES, WEIGHTS } from '../../models/main';

@Component({
  selector: 'app-select[type][control]',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  @Input() label: string = '';
  @Input() placeholder: string = this.translate.instant('input.select');
  @Input() permissions: Record<string, PERMACTION[]> | undefined;

  @Input() set control(value: AbstractControl | null) {
    this._control = value as FormControl;
  }

  @Input() set type(value: 'astronomicalBody' | 'weight' | 'length' | 'locale' | 'transportType' | 'paymentMethod' | 'package' | 'document' | 'notificationTime' | 'trailerType' | 'customsClearance' | 'release' | 'volume' | 'temperature' | 'plans' | 'eventClass' | 'eventSystem' | 'terminalType' | 'terminalCompany' | 'resources' | 'airFuelType' | 'maritimFuelType' | 'roadFuelType' | 'railwayFuelType' | 'riverFuelType' | 'interplanetaryFuelType' | 'distance') {
    this._type = value;
    this.updateType();
  }

  _control!: FormControl;
  _type!: string;
  data: string [] = [];
  i18nPath: string = '';
  iconPrefix: string = '';

  constructor(private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.updateType();
  }

  updateType(): void {
    this.i18nPath = 'select.' + this._type + '.';
    switch (this._type) {
      case 'astronomicalBody':
        this.data = Object.values(ASTRONOMICAL_BODY);
        this.iconPrefix = 'space-';
        break;
      case 'weight':
        this.data = Object.values(WEIGHTS);
        break;
      case 'length':
        this.data = Object.values(LENGTHS);
        break;
      case 'locale':
        this.data = Object.values(LOCALES);
        break;
      case 'paymentMethod':
        this.data = Object.values(PAYMENT_METHODS);
        break;
      case 'transportType':
        this.data = Object.values(TRANSPORT_TYPES);
        this.iconPrefix = 'transport-';
        break;
      case 'package':
        this.data = Object.values(PACKAGES);
        break;
      case 'notificationTime':
        this.data = Object.values(NOTIFICATION_TIMES);
        break;
      case 'trailerType':
        this.data = Object.values(TRAILER_TYPES);
        break;
      case 'release':
        this.data = Object.values(RELEASE_TYPES);
        break;
      case 'customsClearance':
        this.data = Object.values(CUSTOMS_CLEARANCE_CODES);
        break;
      case 'volume':
        this.data = Object.values(VOLUMES);
        break;
      case 'temperature':
        this.data = Object.values(TEMPERATURES);
        break;
      case 'terminalType':
        this.data = Object.values(TERMINAL_TYPE);
        break;
      case 'terminalCompany':
        this.data = Object.values(TERMINAL_COMPANY);
        break;
      case 'document':
        if (this.permissions) {
          this.data = Object.values(DOCUMENTS).filter(e => this.permissions?.[e].includes(PERMACTION['create']));
        } else {
          this.data = Object.values(DOCUMENTS);
        }
        this.i18nPath = 'objects.';
        break;
      case 'plans':
        this.data = Object.values(PLANS);
        break;
      case 'eventClass':
        this.data = Object.values(EVENT_CLASS);
        this.iconPrefix = 'eventClass-';
        break;
      case 'eventSystem':
        this.data = Object.values(EVENT_SYSTEM);
        break;
      case 'resources':
        this.data = Object.values(RESOURCE_TYPE);
        break;
      case 'airFuelType':
        this.data = Object.values(FUEL_TYPES_AIR);
        break;
      case 'maritimFuelType':
        this.data = Object.values(FUEL_TYPES_MARITIM);
        break;
      case 'roadFuelType':
        this.data = Object.values(FUEL_TYPES_ROAD);
        break;
      case 'railwayFuelType':
        this.data = Object.values(FUEL_TYPES_RAILWAY);
        break;
      case 'riverFuelType':
        this.data = Object.values(FUEL_TYPES_RIVER);
        break;
      case 'interplanetaryFuelType':
        this.data = Object.values(FUEL_TYPES_INTERPLANETARY);
        break;
      case 'distance':
        this.data = Object.values(DISTANCE);
        break;
    }
  }
}
