import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';


@Component({
  selector: 'app-subscription-card[type][control]',
  templateUrl: './subscription-card.component.html',
  styleUrls: ['./subscription-card.component.scss'],
})
export class SubscriptionCardComponent implements OnInit {
  @Input() type!: 'records' | 'members' | 'storage' | 'total';
  @Input() set control(value: AbstractControl | null) { this.updateForm(value as FormGroup); }
  _form!: FormGroup;

  data = { title: '', firstLabel: '', secondLabel: '', thirdLabel: '', icon: '', decimals: 0 };

  constructor() { }

  ngOnInit(): void {
    switch (this.type) {
      case 'records':
        this.data.title = 'subscription.records';
        this.data.firstLabel = 'subscription.monthIncluded';
        this.data.secondLabel = 'subscription.monthLimit';
        this.data.thirdLabel = 'subscription.extraRecord';
        this.data.icon = 'file-text';
        break;
      case 'storage':
        this.data.title = 'subscription.storage';
        this.data.firstLabel = 'subscription.included';
        this.data.secondLabel = 'subscription.limit';
        this.data.thirdLabel = 'subscription.extraGB';
        this.data.icon = 'save';
        this.data.decimals = 2;
        break;
      case 'members':
        this.data.title = 'subscription.members';
        this.data.firstLabel = 'subscription.included';
        this.data.secondLabel = 'subscription.limit';
        this.data.thirdLabel = 'subscription.extraMember';
        this.data.icon = 'users';
        break;
      case 'total':
        this.data.title = 'subscription.totalPrice';
        this.data.firstLabel = '';
        this.data.secondLabel = '';
        this.data.thirdLabel = '';
        this.data.icon = '';
        break;
    }
  }

  updateForm(form: FormGroup) {
    this._form = form;

    // Si el dia de mañana el form puede cambiar habra q cancelar la suscripcion anterior
    form.get('included')?.valueChanges.subscribe(value => {
      if (value === null) form.get('included')?.disable({ emitEvent: false });
      else form.get('included')?.enable({ emitEvent: false });
    });

    form.get('limit')?.valueChanges.subscribe(value => {
      if (value === null) form.get('limit')?.disable({ emitEvent: false });
      else form.get('limit')?.enable({ emitEvent: false });
    });
  }

  // Si se pulsa el check ponemos el valor adecuado en el input
  disableControl(control: AbstractControl, event: MatCheckboxChange) {
    control.setValue(event.checked ? null : '0');
  }

  // Para evitar que el usuario pueda dejar el input en blanco borrandolo
  changeValue(control: AbstractControl) {
    if (control.value === '') control.setValue(null);
  }
}
