import { Component } from '@angular/core';

import packageJSON from '../../../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {

  currentYear: number = new Date().getFullYear();
  version = packageJSON.version;
  constructor() { }

}
