import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Organization } from '../../../../../common/models/main';
import { ApiService } from '../../../../../common/services/api.service';

@Component({
  selector: 'app-organization-detail',
  templateUrl: './organization-detail.component.html',
  styleUrls: ['./organization-detail.component.scss'],
})
export class OrganizationDetailComponent implements OnInit {

  organization!: Organization;

  constructor(private router: Router, private ar: ActivatedRoute, private api: ApiService) {
  }

  ngOnInit(): void {
    this.ar.params.subscribe((params) => {
      this.api.getOrganization(params.id).subscribe(res => {
        this.organization = res;
      });
    });
  }
}
