import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ApplicationsFormComponent } from '../../../common/components/applications-form/applications-form.component';
import { Logged } from './core/guard/auth.guard';
import { HomeBOComponent } from './routes/home-bo/home-bo.component';
import { LoginBOComponent } from './routes/login-bo/login-bo.component';
import { BillingComponent } from './routes/organization-detail/billing/billing.component';
import { OrganizationDataComponent } from './routes/organization-detail/organization-data/organization-data.component';
import { OrganizationDetailComponent } from './routes/organization-detail/organization-detail.component';
import { SubscriptionComponent } from './routes/organization-detail/subscription/subscription.component';
import { OrganizationListComponent } from './routes/organization-list/organization-list.component';

const routes: Routes = [
  { path: 'login', component: LoginBOComponent },
  {
    path: '',
    component: HomeBOComponent,
    children: [
      { path: '', redirectTo: '/organization-list', pathMatch: 'full' },
      { path: 'organization-list', component: OrganizationListComponent },
      {
        path: 'organization-detail/:id',
        component: OrganizationDetailComponent,
        children: [
          { path: 'organization-data', component: OrganizationDataComponent },
          { path: 'subscription', component: SubscriptionComponent },
          { path: 'billing', component: BillingComponent },
          { path: 'applications', component: ApplicationsFormComponent },
        ],
      },
    ],
    canActivate: [Logged],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
