<div *ngIf="_form" class="flex flex-col card w-52 p-4 shadow-standard">
  <div class="flex mb-3">
    <app-avatar-circle [type]="'icon'" [data]="data.icon" *ngIf="type !== 'total'"></app-avatar-circle>
    <div class="flex items-center ml-2 overline-o1">
      <div>{{data.title | translate}}</div>
    </div>
  </div>
  <div *ngIf="type !== 'total'">
    <div>
      <div class="flex items-center space-x-2">
        <app-input [type]="'number'" [decimals]="data.decimals" [control]="_form.controls.included" [label]="data.firstLabel | translate" (change)="changeValue(_form.controls.included)"></app-input>
        <mat-checkbox [checked]="_form.controls.included.value === null" (change)="disableControl(_form.controls.included, $event)">{{'subscription.limitless' | translate}}</mat-checkbox>
      </div>
    </div>
    <div>
      <div class="flex items-center space-x-2">
        <app-input [type]="'number'" [decimals]="data.decimals" [control]="_form.controls.limit" [label]="data.secondLabel | translate" (change)="changeValue(_form.controls.limit)"></app-input>
        <mat-checkbox [checked]="_form.controls.limit.value === null" (change)="disableControl(_form.controls.limit, $event)">{{'subscription.limitless' | translate}}</mat-checkbox>
      </div>
    </div>
    <div>
      <app-input [type]="'number'" [decimals]="2" [control]="_form.get('extraPrice.amount')"  [placeholder]="'0,00'" [label]="data.thirdLabel | translate" (change)="changeValue(_form.controls.included)">
        {{_form.get('extraPrice.currency')?.value}}
      </app-input>
    </div>
  </div>
  <div *ngIf="type === 'total'">
    <app-input [type]="'number'" [decimals]="2" [control]="_form.controls.amount"  [placeholder]="'0,00'" [label]="data.thirdLabel | translate" (change)="changeValue(_form.controls.amount)">
      {{_form.get('currency')?.value}}
    </app-input>
  </div>
</div>
