import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { OrganizationExtended } from '../../../../../../common/models/main';
import { ApiService } from '../../../../../../common/services/api.service';

@UntilDestroy()
@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit {
  organizationId = '';
  organization!: OrganizationExtended;

  constructor(private ar: ActivatedRoute, private api: ApiService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.ar.params.pipe(untilDestroyed(this)).subscribe((params) => {
      this.organizationId = params.id;
      this.api.getOrganization(this.organizationId).subscribe(res => {
        this.organization = res;
      });
    });
  }
}
