<div class="h-full overflow-hidden">
  <h1 *ngIf="organization" class="title-t0 mx-4 mt-6 mb-0 grey-title">{{organization.name}}</h1>
  <nav mat-tab-nav-bar class="mat-tab-link-container" [tabPanel]="tabPanel">
    <div class="flex justify-between items-center w-full mx-4 border-bottom">
      <div class="flex border-bottom-nav">
        <a mat-tab-link routerLinkActive="active-link" routerLink="organization-data" class="overline-o1">{{'sections.organization' | translate}}</a>
        <a mat-tab-link routerLinkActive="active-link" routerLink="applications" class="overline-o1">{{'sections.applications' | translate}}</a>
        <a mat-tab-link routerLinkActive="active-link" routerLink="subscription" class="overline-o1">{{'sections.subscription' | translate}}</a>
        <a mat-tab-link routerLinkActive="active-link" routerLink="billing" class="overline-o1">{{'sections.billing' | translate}}</a>
      </div>
    </div>
  </nav>
  <div class="h-content px-6">
    <mat-tab-nav-panel #tabPanel>
      <router-outlet></router-outlet>
    </mat-tab-nav-panel>
  </div>
</div>
