import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { noop } from 'rxjs';

import { DEFAULT } from '../../../../../../common/models/constants';
import { OrganizationExtended } from '../../../../../../common/models/main';
import { ApiService, CustomQuery } from '../../../../../../common/services/api.service';


@UntilDestroy()
@Component({
  selector: 'app-organization-data',
  templateUrl: './organization-data.component.html',
  styleUrls: ['./organization-data.component.scss'],
})
export class OrganizationDataComponent implements OnInit {
  dataSource: MatTableDataSource<Record<string, any>> = new MatTableDataSource<Record<string, any>>([]);
  displayedColumns: string[] = ['name', 'email', 'invitedAt', 'registeredAt', 'enabled', 'admin', 'manager'];
  organizationId = '';
  organization!: OrganizationExtended;
  query: CustomQuery = { pagination: { offset: 0, limit: DEFAULT.PAGE_SIZE_OPTIONS[0] } };
  enabledCargoX = false;

  constructor(private ar: ActivatedRoute, private api: ApiService) {
  }

  ngOnInit(): void {
    this.ar.params.pipe(untilDestroyed(this)).subscribe((params) => {
      this.organizationId = params.id;
      this.api.getOrganization(this.organizationId).subscribe(res => {
        this.organization = res;
        if (res.integrations?.cargox) this.enabledCargoX = res.integrations.cargox.available;
        this.api.getMembersFormOrganization(this.organizationId, this.query).subscribe(res => {
          this.dataSource.data = res.data;
        });
      });
    });
  }

  patchMember(event: MatCheckboxChange, id: string) {
    const status = event.checked ? 'active' : 'disabled';
    this.api.updateMembersStatus(id, status).subscribe({
      next: noop,
      error: () => {
        // Algun dia lo haremos mejor, not today
        this.api.logWarn('No puedes activar a ese usuario debido a que ya esta en el limite de su plan', false);
      },
    });
  }
}
