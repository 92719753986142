<div class="flex flex-col absolute inset-0">
  <mat-toolbar class="flex justify-between border-bottom">

    <div class="flex items-center ml-4">
      <img src="./assets/img/logo-usyncro_black-150.png">
      <div class="mx-4 title-t2 uppercase title-backoffice">backoffice</div>
    </div>

    <div class="navbar flex items-center">
      <div class="navbar__item flex justify-center items-center">
        <app-menu-locale></app-menu-locale>
      </div>
      <div class="navbar__item flex justify-center items-center">
        <mat-icon (click)="exit('/login')" svgIcon="exit" class="action mat-icon-l" data-test="logoutButton"></mat-icon>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="flex-1">
    <mat-sidenav opened [mode]="'side'">
      <mat-nav-list>
        <a mat-list-item routerLink="/organization-list">
          <mat-icon svgIcon="clipboard" class="mr-2 mat-icon-l"></mat-icon>
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
