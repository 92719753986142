import { Component, OnInit } from '@angular/core';
import { FormControl, NonNullableFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin } from 'rxjs';

import { DEFAULT } from '../../../../../../common/models/constants';
import { OrganizationExtended, Plan } from '../../../../../../common/models/main';
import { ApiService } from '../../../../../../common/services/api.service';

@UntilDestroy()
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
  organization!: OrganizationExtended;
  plans!: Plan[];
  plansForm = this.fb.control('');
  form = this.fb.group({
    plan: this.fb.group({ id: [''], type: new FormControl<'plans'>('plans', { nonNullable: true }) }),
    monthlyPrice: this.fb.group({
      amount: ['0'],
      currency: [DEFAULT.CURRENCY],
    }),
    products: this.fb.group({
      records: this.createProductSubcription(),
      members: this.createProductSubcription(),
      storage: this.createProductSubcription(),
    }),
  });

  constructor(private ar: ActivatedRoute, private api: ApiService, private fb: NonNullableFormBuilder) { }

  ngOnInit(): void {
    this.ar.params.pipe(untilDestroyed(this)).subscribe((params) => {
      forkJoin([this.api.getOrganization(params.id), this.api.getPlans()]).subscribe(([org, plans]) => {
        this.plans = plans;
        this.organization = org;
        this.form.patchValue(this.organization.subscription);

        const plan = this.plans.find(e => e.id === org.subscription.plan.id);
        if (!plan) return console.error('Plan no encontrado, esto no deberia suceder');
        this.plansForm.setValue(plan.key);

        // Aqui para que NO se actualice al cargar los datos del back
        this.plansForm.valueChanges.subscribe(value => {
          const plan = this.plans.find(e => e.key === value);
          if (!plan) return console.error('Plan no definido, esto no deberia suceder');
          this.form.patchValue({ plan: { id: plan.id }, monthlyPrice: plan.monthlyPrice, products: plan.products });
        });
      });
    });

    // Aqui para que se actualice al cargar los datos del back
    this.form.controls.monthlyPrice.controls.currency.valueChanges.subscribe((currency) => {
      this.form.get('products.members.extraPrice.currency')?.setValue(currency);
      this.form.get('products.storage.extraPrice.currency')?.setValue(currency);
      this.form.get('products.records.extraPrice.currency')?.setValue(currency);
    });
  }

  createProductSubcription() {
    return this.fb.group({
      included: new FormControl<string | null>('0'),
      extraPrice: this.fb.group({
        amount: ['0'],
        currency: [''],
      }),
      limit: new FormControl<string | null>('0'),
    });
  }

  updateSubscription() {
    const data = this.form.getRawValue();

    this.api.updateSubscription(this.organization.id, data).subscribe(() => {
      this.api.log('subscription.planSaved');
    });
  }
}
