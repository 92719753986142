import { Injectable, Injector } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, ReplaySubject } from 'rxjs';

import { User } from '../../../../../common/models/main';
import { MainCacheService } from '../../../../../common/shared/main-chache.service';

@Injectable({ providedIn: 'root' })
export class CacheService extends MainCacheService {

  constructor(protected translate: TranslateService, protected injector: Injector, protected dateAdapter: DateAdapter<Date>) {
    super(translate, injector, dateAdapter);
  }

  // Pide usuario y emite evento. Deja de usarlo con .toPromise() no funciona. Usa getUserPromise.
  getUser(): ReplaySubject<User> {
    if (this._$user) return this._$user;
    if (!this.getToken()) throw new Error('No hay token para poder obtener obtener el usuario');

    this._$user = new ReplaySubject<User>(1);
    this.api.getAdmin().subscribe(({ user }) => {
      this._$user?.next(user);
    });

    return this._$user;
  }

  getUserPromise(): Promise<User> {
    return firstValueFrom(this.getUser().asObservable());
  }
}
