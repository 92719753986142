import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';

import { DEFAULT } from '../../../../../common/models/constants';
import { ApiService, CustomQuery } from '../../../../../common/services/api.service';
import { HelperService } from '../../../../../front/src/app/core/services/helper.service';

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss'],
})
export class OrganizationListComponent implements OnInit {

  dataSource: MatTableDataSource<Record<string, any>> = new MatTableDataSource<Record<string, any>>([]);
  displayedColumns: string[] = ['name', 'id', 'createdAt', 'createdBy', 'records'];
  PAGE_SIZE_OPTIONS = DEFAULT.PAGE_SIZE_OPTIONS;
  query: CustomQuery = { filter: {}, pagination: { offset: 0, limit: DEFAULT.PAGE_SIZE_OPTIONS[0] } };
  totalElements = 0;
  subscription!: Subscription;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private api: ApiService, public helper: HelperService) {
  }

  ngOnInit(): void {
    this.getOrganizations(this.query);
  }

  getOrganizations(query?: CustomQuery) {
    this.subscription = this.api.getOrganizations(query).subscribe(res => {
      this.dataSource.data = res.data;
      this.totalElements = res.total;
    });
  }

  getPage(event: PageEvent) {
    this.query.pagination!.limit = event.pageSize;
    this.query.pagination!.offset = event.pageSize * event.pageIndex;
    this.getOrganizations(this.query);
  }

  sortOrganizations(sortState: Sort) {
    const query = this.helper.sortItems(sortState);
    this.getOrganizations(query);
  }

}
