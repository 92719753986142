<div class="h-full overflow-y-auto">
  <div class="px-7 py-4 mt-2">
    <span class="title-t1">{{'organization.title' | translate}}</span>
  </div>
  <div>
    <div class="p-3 pb-10 mx-4">
      <app-table-no-data [dataSource]="dataSource" [subscription]="subscription" [message]="('organization.noRecords' | translate)">
        <div class="shadow-dark">
          <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortOrganizations($event)" class="w-full" data-test="organizationTable">
            <!-- name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>{{'organization.name' | translate}}</th>
              <td mat-cell *matCellDef="let element">
                <div class="flex flex-col">
                  <a class="link" [routerLink]="['/organization-detail', element.id, 'organization-data']" data-test="organizationName">{{element.name}}</a>
                  <div>{{element.alias}}</div>
                </div>
              </td>
            </ng-container>

            <!-- Id Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>{{'organization.id' | translate}}</th>
              <td mat-cell *matCellDef="let element">
                <div class="flex items-center">
                  <span class="ml-2" data-test="organizationId">{{element.legalId}}</span>
                </div>
              </td>
            </ng-container>

            <!-- created Date Column -->
            <ng-container matColumnDef="createdAt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'organization.createdAt' | translate}}</th>
              <td mat-cell *matCellDef="let element" data-test="organizationCreatedAt"> {{element.createdAt | date: 'short'}} </td>
            </ng-container>

            <!-- created by Column -->
            <ng-container matColumnDef="createdBy">
              <th mat-header-cell *matHeaderCellDef>{{'organization.createdBy' | translate}}</th>
              <td mat-cell *matCellDef="let element" data-test="organizationCreatedBy"> {{element.createdBy.email }} </td>
            </ng-container>

            <!-- records Column -->
            <ng-container matColumnDef="records">
              <th mat-header-cell *matHeaderCellDef>{{'organization.records' | translate}}</th>
              <td mat-cell *matCellDef="let element">
                <div class="flex justify-center w-full">
                  <span data-test="organizationRecords">{{element.recordCount}}</span>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
          </table>

          <div class="flex justify-between bg-color pb-5">
            <mat-paginator (page)="getPage($event)" [length]="totalElements" [pageSizeOptions]="PAGE_SIZE_OPTIONS" showFirstLastButtons [ngClass]="{'hidden': !dataSource.data.length}" class="w-full"></mat-paginator>
          </div>
        </div>
      </app-table-no-data>
    </div>
  </div>
</div>
