import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { ApiService } from '../../../../../../common/services/api.service';
import { CustomValidators } from '../../../../../../common/shared/custom-validators';

@Component({
  selector: 'app-recovery-password-modal',
  templateUrl: './recovery-password-modal.component.html',
  styleUrls: ['./recovery-password-modal.component.scss'],
})
export class RecoveryPasswordModalComponent {
  form = this.fb.nonNullable.group({
    email: ['', [Validators.required, CustomValidators.validateEmail]],
  });

  sent = false;

  constructor(public dialogRef: MatDialogRef<RecoveryPasswordModalComponent>, private fb: FormBuilder, private api: ApiService) { }

  sendMail() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return this.api.logError('formErrors.invalidForm');
    }
    const data = this.form.getRawValue();
    if (data.email) data.email = data.email.trim();
    this.api.recoverPasswordBO(data).subscribe(() => {
      this.sent = true;
    });
  }

  close() {
    this.dialogRef.close();
  }
}
